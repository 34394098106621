<template>
  <div class="record-screening-result-wrap">
    <Breadcrumb :breadcrumbObj="breadcrumbObj" @confirm_0="lookUpAndRecord()" @confirm_1="openQeury()"></Breadcrumb>
    <!-- record -->
    <div class="record-wrap">
      <el-collapse v-model="recordItemActiveName">
        <el-collapse-item title="Booking List" name="Booking List" class="booking-list">
          <el-form label-position="top" label-width="80px" :inline="true" class="booking-form">
            <el-form-item label="Booking No.">
              <el-autocomplete
                class="inline-input"
                v-model="bookingListParams.bookingNo"
                size="medium"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="bookingNoSelect"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item class="form-btn">
              <el-button plain size="small" @click="AddSubmit">Add</el-button>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item title="Screening Result" name="Screening Result">
          <div class="screening-result">
            <el-form label-position="top" label-width="80px" :inline="true" class="result-radio-form">
              <el-form-item label="Screening Method">
                <el-checkbox-group v-model="screeningResultParams.screeningMethod">
                  <el-checkbox label="X-Ray"></el-checkbox>
                  <el-checkbox label="ETD"></el-checkbox>
                  <el-checkbox label="Hand Search"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="Screening Result">
                <el-radio-group v-model="screeningResultParams.screeningResult">
                  <el-radio :label="3">Pass</el-radio>
                  <el-radio :label="6">Reject</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <el-form label-position="top" label-width="80px" :inline="true" class="result-text-form">
              <el-form-item label="Screener ID">
                <el-select v-model="screeningResultParams.screenerID" placeholder="请选择" clearable size="medium">
                  <el-option
                    v-for="item in screenerIDOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Screening Date">
                <el-date-picker
                  v-model="screeningResultParams.screeningDate"
                  type="daterange"
                  format="yyyy - MM - dd"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  size="medium"
                >
                </el-date-picker>
                <svg-icon icon-class="calendar" style="position: relative;left: -10px;top: 3px;" />
              </el-form-item>
              <el-form-item label="Screening Time">
                <el-input v-model="screeningResultParams.screeningTime" placeholder="Input suffix to search ( ≥3 chars)" size="medium" clearable></el-input>
              </el-form-item>
              <el-form-item label="Screening Remark">
                <el-input v-model="screeningResultParams.screeningRemark" placeholder="Input...(Optional)" size="medium" clearable></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Printing Options" name="Printing Options">
          <el-checkbox v-model="printingOptionsParams.checkPrintResult">Print Security Screening Receipt</el-checkbox>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'

export default {
  name: 'record-screening-result',
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadcrumbObj: { // 标题头内容
        title: [
          {
            name: 'Cargo Receiving',
            path: '/cargo-receiving/security-screening/list'
          },
          {
            name: 'Security Screening',
            path: '/cargo-receiving/security-screening/list'
          },
          {
            name: 'Record Screening Result',
            path: '/cargo-receiving/security-screening/record-screening-result'
          }
        ],
        button: []
      },
      recordItemActiveName: ['Booking List', 'Screening Result', 'Printing Options'],
      bookingListParams: {
        bookingNo: ''
      },
      screeningResultParams: {
        screeningMethod: [],
        screenerID: '',
        screeningDate: '',
        screeningTime: '',
        screeningRemark: ''
      },
      printingOptionsParams: {
        checkPrintResult: false
      },
      screenerIDOptions: [
        {
          label: '9090',
          value: '9090'
        }
      ]
    }
  },
  mounted () {
    this.restaurants = this.loadAll()
  },
  methods: {
    loadAll () {
      return [
        {
          value: 'UHK-08749521',
          address: 'UHK-08749521'
        },
        {
          value: 'UHK-08783596',
          address: 'UHK-08783596'
        },
        {
          value: 'UHK-08783585',
          address: 'UHK-08783585'
        }
      ]
    },
    AddSubmit () {
      console.log('AddSubmit')
    },
    querySearch (queryString, cb) {
      if (queryString.length >= 3) {
        console.log('queryString', queryString)
        var restaurants = this.restaurants
        var results = queryString.length >= 3 ? restaurants.filter(this.createFilter(queryString)) : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      }
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    bookingNoSelect (item) {
      console.log('bookingNoSelect', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  .record-wrap{
    padding: 24px;
    .booking-list{
      ::v-deep{
        .el-form{
          display: flex;
          align-items: flex-end;
        }
        .el-form--inline .el-form-item{
          width: 531px;
          margin-right: 24px;
          margin-bottom: 0;
        }
        .el-autocomplete {
          width: 100%;
        }
        .form-btn{
          width: 61px !important;
          margin-right: 0;
          margin-bottom: 0;
        }
        .el-button{
          border-color: #1890ff;
          color: #1890ff;
        }
      }
    }
    .screening-result{
      .result-radio-form{
        margin-bottom: 24px;
        ::v-deep{
          .el-form-item{
            width: 50%;
            margin-right: 0;
            margin-bottom: 0;
          }
          .el-form-item__content{
            line-height: 22px;
          }
          .el-form-item__label{
            margin-bottom: 8px;
          }
          .el-radio{
            margin-right: 108px;
          }
          .el-checkbox{
            margin-right: 80px;
          }
        }
      }
      .result-text-form{
        display: flex;
        justify-content: space-between;
        ::v-deep{
          .el-form-item{
            width: 24%;
            margin-right: 0;
            margin-bottom: 0;
          }
          .el-select{
            width: 100%;
          }
          .el-range__icon {
            display: none;
          }
          .el-range-input {
            width: 90px;
            text-align: justify;
          }
          .el-input__inner {
            color: #333;
          }
          .el-range-editor.el-input__inner {
            margin-right: -22px;
          }
          .el-date-editor--daterange.el-input__inner{
            width: 100%;
          }
        }
      }
    }
    ::v-deep{
      .el-collapse-item__header {
          display: flex;
          align-items: center;
          height: 14px;
          line-height: 14px;
          background-color: #F7F7F7;
          color: #666666;
          cursor: pointer;
          border-bottom: none;
          font-size: 14px;
          font-weight: 900;
          transition: border-bottom-color .3s;
          outline: 0;
          border: 1px solid #E5E5E5;
          border-radius: 4px 4px 0px 0px;
          padding: 16px 24px;
      }
      .el-collapse {
          border-top: none;
          border-bottom: none;
          min-height: 634px;
      }
      .el-collapse-item{
          margin-bottom: 24px;
      }
      .el-collapse-item__wrap{
        border: 1px solid #EBEEF5;
        border-top: none;
        border-radius: 0 0 8px 8px;
      }
      .el-collapse-item__content{
        padding: 24px;
      }
      .el-form-item__label{
        line-height: 22px;
        padding: 0;
        color: #666666;
      }
    }
  }
</style>
